import styled from '@emotion/styled';
import React from 'react';

import FlexColumn from '../../layout/FlexColumn';
import FlexRow from '../../layout/FlexRow';
import Spacer from '../../layout/Spacer';
import { ItipsLogoDark } from '../Logo';

const Footer = () => {
  return (
    <Wrapper id="contact">
      <Spacer height="min(111px, 7.708vw)" />
      <FlexColumn className="container" gap="min(20px, 5vw)">
        <div style={{ maxWidth: '150px' }}>
          <a href={'/#top'}>
            <ItipsLogoDark />
          </a>
        </div>

        <FlexRow gap="min(192px, 13.333vw)" className="body">
          <div className="left">
            <a className="left-title" href={'#japan'}>
              Nagoya, Japan Office
            </a>
            <p className="left-content">
              Address 4-60-12 11F Global Gate Bldg, Hiraike-Cho, Nakmura-ku, Nagaoya, Aichi, Japan
            </p>
            <a className="left-title" href={'#india'}>
              Registered Office, India
            </a>
            <p className="left-content">
              iTips Private Limited, WeWork Galaxy, 43 Residency Rd, Shanthala Nagar, Ashok Nagar
              Bengaluru, Karnataka, India 560025
            </p>
          </div>

          <div className="right">
            <div className="right-top">
              <a className="link-bold" href={'/#top'}>
                <p className="border-animation">Top</p>
              </a>
              <a className="link" href={'/#mission'}>
                <p className="border-animation">Mission</p>
              </a>
              <a className="link" href={'/#company'}>
                <p className="border-animation">Company</p>
              </a>
            </div>
            <div className="right-top">
              <a className="link-bold" href={'/service'}>
                <p className="border-animation">Service</p>
              </a>
            </div>
            <div className="right-top">
              <a className="link-bold" href={'/career'}>
                <p className="border-animation">Career</p>
              </a>
            </div>
            <div className="right-top">
              <a className="link-bold" href={'/#contact'}>
                <p className="border-animation">Contact</p>
              </a>
            </div>
          </div>
        </FlexRow>
      </FlexColumn>

      <Spacer height="min(150px, 7.708vw)" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #204e73;
  color: #ffffff;
  line-height: 2;
  font-size: min(16px, 1.3vw);

  .container {
    width: 80%;
    max-width: 1003px;
    margin: 0 auto;
  }

  .header {
    width: 5.972vw;
    max-width: 86px;
    min-width: 40px;

    @media screen and (max-width: 768px) {
      width: 20vw;
      margin-bottom: 16px;
    }
  }

  .body {
    justify-content: space-between;
    align-items: flex-start;
  }

  .left {
    width: 40%;
    display: flex;
    flex-direction: column;

    &-title {
      font-weight: bold;
    }

    &-content {
      opacity: 0.75;
      margin-bottom: 10px;
    }
  }

  .right {
    display: flex;
    justify-content: space-between;
    width: 60%;

    @media screen and (max-width: 768px) {
      width: 100%;
      flex-direction: column;
    }

    &-top {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        flex-direction: row;
      }
    }
  }

  .link {
    opacity: 0.75;
    margin: 4px 0;

    &-bold {
      font-weight: bold;
      font-size: min(20px, 1.3vw);
      margin-bottom: 4px;
    }
  }

  .border-animation {
    position: relative;
    display: inline-block;
  }

  .border-animation::after {
    position: absolute;
    left: 0;
    content: '';
    width: 100%;
    height: 2px;
    background: #ffffff;
    bottom: 1px;
    transform: scale(0, 1);
    transform-origin: center top;
    transition: transform 0.3s;
  }

  .border-animation:hover:after {
    transform: scale(1, 1);
  }

  @media screen and (max-width: 768px) {
    background-color: #204e73;
    color: #ffffff;
    line-height: 2;
    font-size: min(16px, 1.3vw);

    .container {
      width: 80%;
      max-width: 1003px;
      margin: 0 auto;
      gap: 0px;
    }

    .body {
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column-reverse;
      gap: 10px;
    }

    .link {
      opacity: 0.75;
      display: none;

      &-bold {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 6px;
      }
    }

    .border-animation {
      position: relative;
      display: inline-block;
    }

    .border-animation::after {
      position: absolute;
      left: 0;
      content: '';
      width: 100%;
      height: 2px;
      background: #ffffff;
      bottom: 1px;
      transform: scale(0, 1);
      transform-origin: center top;
      transition: transform 0.3s;
    }

    .border-animation:hover:after {
      transform: scale(1, 1);
    }
  }
`;

export default Footer;
