import styled from '@emotion/styled';

const FlexRow = styled.div<{
  gap?: string;
}>`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: ${(props) => props.gap || '0'};
`;

export default FlexRow;
