import { Link } from 'gatsby';
import React from 'react';

import { MyImage } from './MyImage';

export const ItipsLogoDark = () => {
  return <MyImage src="/logo/HeaderLogo.svg" alt="iTips inc." />;
};
export const ItipsLogo = () => {
  return <MyImage src="/logo/logo_overview.webp" alt="iTips inc." />;
};

export const ItipsLogoLink = () => {
  return (
    <Link to="/">
      <ItipsLogoDark />
    </Link>
  );
};
