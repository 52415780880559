import styled from '@emotion/styled';

const FlexColumn = styled.div<{
  gap?: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || '0'};
`;

export default FlexColumn;
